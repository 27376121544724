import React from "react"
import { Grid, Skeleton } from "@chakra-ui/react"

import FeaturedProductsItem from "@app/components/Sections/FeaturedProducts/FeaturedProductsItem"
import { NormalisedProduct } from "@root/types/custom-types/Product/Product"

type Props = {
  items: NormalisedProduct[] | undefined
  isLoading?: boolean
}

const CollectionGrid: React.FC<Props> = ({ items, isLoading }) => {
  return (
    <Grid
      templateColumns={{ base: "repeat(2, calc(50% - 8px))", md: "repeat(4, 1fr)" }}
      columnGap={{ base: "4", lg: "8" }}
      rowGap={{ base: "7", lg: "12" }}
      px={{ base: "4", lg: "20" }}
      pt={{ base: "5", lg: "12" }}
    >
      {items && items.length && !isLoading
        ? items.map((item, index) => (
            <FeaturedProductsItem key={item.handle} item={item} itemPosition={index} itemListName="Collection Page" />
          ))
        : [...Array(12)].map((i, index) => <Skeleton key={index} pb="160%"></Skeleton>)}
    </Grid>
  )
}

export default React.memo(CollectionGrid)
