import { useCallback, useEffect, useState } from "react"

import { useFunctions } from "@app/hooks/useFunctions"

export type YotpoStoreReview = {
  id: number
  score: number
  votes_up: number
  votes_down: number
  content: string
  title: string
  sentiment: number
  created_at: string
  verified_buyer: boolean
  product_id: number
  user: {
    user_id: number
    display_name: string
    social_image: string
    user_type: string
    is_social_connected: number
  }
}

export type YotpoStoreReviews = {
  pagination: {
    page: number
    per_page: number
    total: number
  }
  reviews: YotpoStoreReview[]
  bottomline: {
    average_score: number
    custom_fields_bottomline: any
    total_review: number
    total_organic_reviews: number
    organic_average_score: number
    star_distribution: any
  }
}

export const useReviews = (): UseReviews => {
  const { callFunction } = useFunctions()

  const useProductReviews = (payload: ProductReviewsPayload) => {
    const { id } = payload || {}
    const [reviews, setReviews] = useState<ProductReviewsResponse | null>(null)

    const fetchReviews = useCallback(async () => {
      const response = await callFunction("reviews", { type: "product", id })

      const { pagination, reviews } = response.body.response || {}

      setReviews({ pagination, reviews })
    }, [id, setReviews])

    useEffect(() => {
      fetchReviews()
    }, [fetchReviews])

    return reviews
  }

  const useStoreReviews = (payload?: StoreReviewsPayload) => {
    const { page, perPage } = payload || {}
    const [reviews, setReviews] = useState<StoreReviewsResponse | null>(null)

    const fetchReviews = useCallback(async () => {
      const response = await callFunction("reviews", { type: "store", page, perPage })

      const { pagination, reviews, bottomline } = response?.body?.response || {}

      setReviews({ pagination, reviews, bottomline })
    }, [page, perPage, setReviews])

    useEffect(() => {
      fetchReviews()
    }, [fetchReviews])

    return reviews
  }

  return {
    useProductReviews,
    useStoreReviews,
  }
}

type ProductReviewsPayload = { id: number | string }

type StoreReviewsPayload = { page?: number; perPage?: number }

type ProductReviewsResponse = { pagination: any; reviews: Array<any> }

type StoreReviewsResponse = YotpoStoreReviews

type UseReviews = {
  useProductReviews: (arg0: ProductReviewsPayload) => ProductReviewsResponse | null
  useStoreReviews: (arg0?: StoreReviewsPayload) => StoreReviewsResponse | null
}
