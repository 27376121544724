import React from "react"
import { Box, Flex } from "@chakra-ui/react"

import { Icon } from "@app/components/Icon"

type Props = {
  score: number
  round: string
}

const ReviewRating: React.FC<Props> = ({ score, round = "down" }) => {
  const maxRating = 5
  const roundedScore = round === "up" ? Math.ceil(score) : Math.floor(score)

  return (
    <Box pos="relative">
      <Flex gap="1" w="24">
        {[...Array(maxRating)].map((x, i) => (
          <Box key={i} as="span" w="4" h="4" color="background.starInactive">
            <Icon name="ui/star" width="100%" height="100%" />
          </Box>
        ))}
      </Flex>

      <Flex pos="absolute" top="0" left="0" w={"full"} h="full" zIndex="overlay" overflow="hidden" gap="1">
        {[...Array(roundedScore)].map((x, i) => (
          <Box key={i} as="span" w="4" h="4" color="background.starActive">
            <Icon name="ui/star" width="100%" height="100%" />
          </Box>
        ))}
      </Flex>
    </Box>
  )
}

export default React.memo(ReviewRating)
