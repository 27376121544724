import React, { useEffect, useRef } from "react"
import { Box, Container, Heading, Skeleton } from "@chakra-ui/react"
import SwiperCore, { Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"

import { useAppContext } from "@app/providers/app"
import { useReviews } from "@app/hooks/useReviews"
import { AnalyticProps, withSection } from "@app/hoc/Section"
import ReviewItem from "@app/components/Sections/Reviews/ReviewItem"

SwiperCore.use([Pagination])

export type ReviewTheme = "primary" | "secondary"

type ThemeColor = {
  primary: string
  secondary: string
}

export type SectionColors = {
  heading: ThemeColor
  body: ThemeColor
  background: ThemeColor
  dotActive: ThemeColor
  dotDefault: ThemeColor
}

type Props = GatsbyTypes.SanitySectionReviews &
  AnalyticProps & {
    theme?: ReviewTheme
  }

const Reviews: React.FC<Props> = ({ menuTitle, title, innerRef, theme = "primary", tag }) => {
  const { useStoreReviews } = useReviews()
  const reviewsResponse = useStoreReviews()
  const { saveProductItems } = useAppContext()

  const id = Math.random().toString(16).slice(2)
  const sectionColors = {
    heading: {
      primary: "background.white",
      secondary: "typography.headlines900",
    },
    body: {
      primary: "background.white",
      secondary: "typography.body700",
    },
    background: {
      primary: "brand.secondaryFocus",
      secondary: "background.beige",
    },
    dotActive: {
      primary: "background.white",
      secondary: "brand.primary",
    },
    dotDefault: {
      primary: "rgba(255, 255, 255, 0.5)",
      secondary: "border.default",
    },
  }

  const sectionRef = useRef<HTMLDivElement>(null)

  useEffect(() => saveProductItems(menuTitle, sectionRef), [sectionRef, saveProductItems, menuTitle])

  return (
    <Box as="section" ref={sectionRef} bg={sectionColors.background[theme]} textAlign="center">
      <Container ref={innerRef} maxW="container.4xl" px={{ base: "4", lg: "20" }} py={{ base: "8", lg: "22.5" }}>
        {title && (
          <Heading as={tag} size="h3" mb={{ base: "5", lg: "12" }} color={sectionColors.heading[theme]}>
            {title}
          </Heading>
        )}

        <Box
          sx={{
            ".swiper-slide": { bg: "transparent" },
            ".swiper-pagination": { d: "flex", justifyContent: "center", gap: "2.5", mt: { base: "5", lg: "12" } },
            ".swiper-pagination-bullet": {
              d: "inline-block",
              w: "1.5",
              h: "1.5",
              borderRadius: "full",
              bg: sectionColors.dotDefault[theme],
              cursor: "pointer",
              transition: "background 0.2s ease",
            },
            ".swiper-pagination-bullet.swiper-pagination-bullet-active": {
              bg: sectionColors.dotActive[theme],
            },
          }}
        >
          <Swiper
            lazy
            loop
            simulateTouch
            slidesPerView={1}
            pagination={{ clickable: true, el: `#pagination-${id}` }}
            breakpoints={{ 1024: { slidesPerView: 3, spaceBetween: 48 } }}
            autoHeight
          >
            {reviewsResponse && reviewsResponse?.reviews?.length
              ? reviewsResponse.reviews.map((review, index) => (
                  <SwiperSlide key={index}>
                    <ReviewItem review={review} theme={theme} sectionColors={sectionColors} />
                  </SwiperSlide>
                ))
              : [...Array(4)].map((i, index) => (
                  <SwiperSlide key={index}>
                    <Skeleton w="full" h={{ base: "44", lg: "48.5" }} />
                  </SwiperSlide>
                ))}
          </Swiper>
          <Box id={`pagination-${id}`} className="swiper-pagination" />
        </Box>
      </Container>
    </Box>
  )
}

export default React.memo(withSection(Reviews))
