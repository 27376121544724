import React from "react"
import { GetServerDataProps, graphql, PageProps } from "gatsby"

import config from "../../config"
import Template from "@app/components/Collection/Collection"

import { ProductFieldsFragment } from "@app/graphql/fragments/Personaliser/ProductFieldsFragmment"
import { ImageFragment } from "@app/graphql/fragments/ImageFragment"
import { VariantFieldsFragment } from "@app/graphql/fragments/Personaliser/VariantFieldsFragment"
import { PriceFragment } from "@app/graphql/fragments/PriceFragment"

import { Override } from "@root/types/custom-types/utility"
import { ProductNode } from "@root/types/custom-types/Product/ProductNode"
import { CollectionPageInfo } from "@root/types/custom-types/Collection/Collection"

type CollectionServerData = { shopify: { productEdges: { node: ProductNode }[]; pageInfo: CollectionPageInfo } }
type CollectionData = GatsbyTypes.TemplateCollectionQuery & GatsbyTypes.TemplateCollectionQueryVariables
export type Props = Override<PageProps, { serverData: CollectionServerData; data: CollectionData }>

export const query = graphql`
  query TemplateCollection($handle: String!) {
    collection: sanityCollection(shopify: { handle: { eq: $handle }, deleted: { ne: true }, published: { eq: true } }) {
      ...SanityCollectionFragmentTemplate
      collectionFilters {
        ...SanityCollectionFragmentTemplate
      }
    }
    template: sanityTemplateCollection {
      bannerTitle
      bannerImages: _rawBannerImages(resolveReferences: { maxDepth: 2 })
      sections: _rawSections(resolveReferences: { maxDepth: 6 })
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    }
  }
  fragment SanityCollectionFragmentTemplate on SanityCollection {
    id
    url
    title
    shortDescriptionHtml
    descriptionHtml
    image
    shopify {
      id
      handle
      seoTitle
      seoDescription
    }
    accordionBlocks: _rawAccordionBlocks(resolveReferences: { maxDepth: 6 })
    seoContent: _rawSeoContent(resolveReferences: { maxDepth: 6 })
    metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    parentCollection: _rawParentCollection(resolveReferences: { maxDepth: 6 })
  }
`

const Component: React.FC<Props> = ({ data, ...props }) => <Template {...props} data={data} />
export default Component

export async function getServerData(context: GetServerDataProps) {
  const { pageContext } = context
  const { shopifyStorefrontToken, shopifyShopDomain, shopifyApiVersion } = config.store

  const handle = pageContext?.handle as string | undefined

  try {
    if (!handle) throw new Error("Missing handle from pageContext")

    const GET_COLLECTION_PRODUCTS_GQL_QUERY = `
    {
      collection(handle: "${handle}") {
        products(first: 250) {
          ...ProductFieldsFragment
          pageInfo {
            hasNextPage
            hasPreviousPage
            endCursor
            startCursor
          }
        }
      }
    }
    ${ProductFieldsFragment}
    ${VariantFieldsFragment}
    ${ImageFragment}
    ${PriceFragment}
    `

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Shopify-Storefront-Access-Token": shopifyStorefrontToken,
      },
      body: JSON.stringify({ query: GET_COLLECTION_PRODUCTS_GQL_QUERY }),
    }
    const shopifyResponse = await fetch(`https://${shopifyShopDomain}/api/${shopifyApiVersion}/graphql.json`, requestOptions)
    const json = await shopifyResponse.json()

    if (json.errors) {
      console.error("Error fetching data:", json.errors)
      throw "Error fetching data"
    }

    return {
      status: 200,
      props: {
        shopify: {
          productEdges: json.data.collection.products.edges,
          pageInfo: json.data.collection.products.pageInfo,
        },
      },
    }
  } catch (error) {
    console.error((error as Error).message)

    return {
      status: 500,
    }
  }
}
