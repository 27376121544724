import React from "react"
import { Button, Text } from "@chakra-ui/react"
import { useState } from "react"

export function TruncatedText({ children, noOfLines = 1 }: any) {
  const [isTruncated, setIsTruncated] = useState(true)
  return (
    <>
      <Text noOfLines={isTruncated ? noOfLines : undefined}>{children}</Text>
      <Button
        fontWeight={"normal"}
        colorScheme="black"
        textTransform={"capitalize"}
        onClick={() => setIsTruncated(!isTruncated)}
        variant={"link"}
      >
        {isTruncated ? "Read more..." : "See less"}
      </Button>
    </>
  )
}
