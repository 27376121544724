import React from "react"
import { Box, Flex, Text } from "@chakra-ui/react"

import { useAppContext } from "@app/providers/app"
import { useRoutes } from "@app/hooks/useRoutes"
import Link from "@app/components/Link"

import { CollectionFilter } from "@root/types/custom-types/Collection/Collection"

type Props = {
  items: CollectionFilter[] | undefined
}

const CollectionFilters: React.FC<Props> = ({ items }) => {
  const { activeCollection } = useAppContext()
  const { urlResolver } = useRoutes()

  return items?.length ? (
    <Box w="full" pt={{ base: "4.5", lg: "10" }} px={{ lg: "20" }} overflow="hidden">
      <Flex
        overflow="auto"
        scrollSnapType="x mandatory"
        scrollPadding={{ base: "4", lg: "0" }}
        sx={{
          scrollbarWidth: { base: "none" },
          "::-webkit-scrollbar": {
            display: { base: "none" },
          },
        }}
        pb="1"
        justifyContent={{ lg: "center" }}
      >
        {items.map(item => {
          const filterUrl = urlResolver(item)
          const isActive = parseInt(item.shopify?.id) === activeCollection?.id
          return (
            <Text
              key={item.shopify?.handle}
              as={Link}
              to={filterUrl.url}
              title={item.title}
              aria-label={item.title}
              display="inline-block"
              size="largeParagraph"
              flexShrink={0}
              scrollSnapAlign="start"
              py="1"
              px="4.5"
              mr="3"
              boxShadow="subtleBottomGlow"
              border="1px solid"
              borderRadius="3xxl"
              cursor="pointer"
              fontWeight={isActive ? "bold" : "inherit"}
              color={isActive ? "background.white" : "typography.headlines900"}
              bg={isActive ? "brand.primary" : "background.white"}
              borderColor={isActive ? "brand.primary" : "border.light"}
              _first={{ ml: { base: "4", lg: "0" } }}
              _last={{ mr: { base: "4", lg: "0" } }}
              _hover={{ bg: "brand.primary", color: "background.white", fontWeight: "bold", borderColor: "brand.primary" }}
            >
              {item?.title}
            </Text>
          )
        })}
      </Flex>
    </Box>
  ) : null
}

export default React.memo(CollectionFilters)
