import React, { useEffect, useMemo, useRef, useState } from "react"
import { navigate } from "gatsby"
import { Box, Button, Container } from "@chakra-ui/react"

import { useAppContext } from "@app/providers/app"
import { useCore } from "@app/hooks/useCore"
import { useShopify } from "@app/hooks/useShopify"
import SimpleHero from "@app/components/Sections/SimpleHero/SimpleHero"
import CollectionGrid from "@app/components/Collection/CollectionGrid"
import CollectionFilters from "@app/components/Collection/CollectionFilters"
import ProductAccordion from "@app/components/Sections/ProductAccordion/ProductAccordion"
import Text from "../Sections/Text/Text"

import { Props } from "@app/templates/collection"

import { NormalisedProduct } from "@root/types/custom-types/Product/Product"
import { ProductNode } from "@root/types/custom-types/Product/ProductNode"
import { TruncatedText } from "../TruncatedText"

const INITIAL_ITEMS_TO_SHOW = 16
const INCREMENT_ITEMS_TO_SHOW_COUNT = 8

const Collection: React.FC<Props> = ({ serverData, data: { template, collection: rawCollection } }) => {
  const {
    shopify: { productEdges },
  } = serverData

  const initialItemsToShowCount = useMemo(() => {
    if (typeof window === "undefined") return INITIAL_ITEMS_TO_SHOW
    const params = new URLSearchParams(window.location.search)
    const itemsToShow = params.get("itemsToShow")
    return itemsToShow ? parseInt(itemsToShow, 10) : INITIAL_ITEMS_TO_SHOW
  }, [])

  const [itemsToShowCount, setItemsToShowCount] = useState(initialItemsToShowCount)

  const { collectionNormaliser, productNormaliser } = useShopify()
  const { activeCollection, setActiveCollection } = useAppContext()
  const {
    helpers: { ErrorBoundary, isDomReady, isBrowser },
  } = useCore()

  const containerRef = useRef<HTMLDivElement>(null)

  const collection = collectionNormaliser(rawCollection)

  const seoTitle = collection?.seoContent?.length ? collection?.seoContent[0]?.title : null
  const seoContent = collection?.seoContent?.length ? collection?.seoContent[0]?.content : null

  const collectionProducts: NormalisedProduct[] = productEdges?.map((edge: { node: ProductNode }) => productNormaliser(edge.node))

  useEffect(() => {
    if (collection?.id !== activeCollection?.id) setActiveCollection(collection)
  }, [collection, activeCollection?.id, setActiveCollection])

  const heroTitle = template?.bannerTitle ? template?.bannerTitle : collection?.title

  const handleShowMoreItems = () => {
    setItemsToShowCount(prev => prev + INCREMENT_ITEMS_TO_SHOW_COUNT)
  }

  useEffect(() => {
    if (!isBrowser) return
    if (itemsToShowCount === initialItemsToShowCount) return
    const params = new URLSearchParams(window.location.search)
    params.set("itemsToShow", itemsToShowCount.toString())
    navigate(`?${params.toString()}`, { replace: true })
  }, [initialItemsToShowCount, isBrowser, itemsToShowCount])

  return (
    <>
      <SimpleHero headingTag={"h1"} title={heroTitle} image={template?.bannerImages} />

      <Container marginBottom="30px" ref={containerRef} maxW={"container.4xl"} px="0">
        <CollectionFilters items={collection?.collectionFilters} />
        {collection?.shortDescriptionHtml ? (
          <Box px={{ base: "4", lg: "20" }} pt={{ base: "5", lg: "12" }}>
            <TruncatedText>{collection?.shortDescriptionHtml}</TruncatedText>
          </Box>
        ) : null}

        <CollectionGrid items={collectionProducts.slice(0, itemsToShowCount)} />

        {collectionProducts?.length > itemsToShowCount && (
          <Box width="100%" marginTop="30px" display="flex" justifyContent="center">
            <Button variant="solidSecondary" onClick={handleShowMoreItems} minW="30">
              See More
            </Button>
          </Box>
        )}
      </Container>
      {seoContent?.length ? <Text content={seoContent} title={seoTitle} /> : null}
      {isDomReady && (
        <ErrorBoundary>
          {(template?.sections as any)?.map((section: any) => {
            const name = section._type?.replace("section", "")
            // eslint-disable-next-line @typescript-eslint/no-var-requires
            const SectionComponent = require(`../Sections/${name}/${name}`).default
            return <SectionComponent key={section._key?.toString()} name={name} {...section} />
          })}
        </ErrorBoundary>
      )}
      {(rawCollection?.accordionBlocks as any)?.length ? (
        <ProductAccordion accordionBlocks={rawCollection?.accordionBlocks as any} />
      ) : null}
    </>
  )
}

export default Collection
